.loginPage {
  min-height: 100vh;
  background: #faeff3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formLoginContainer {
  background-color: #fefefe;
  height: auto;
  width: 350px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 30px;
  border-radius: 12px;
  margin-top: 80px;
}
.formSignContainer {
  background-color: #fefefe;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: auto;
  width: 400px;
  padding: 20px 50px;
  margin-top: 100px;
  border-radius: 12px;
}
.loginForm {
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}
.inputContainer {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.formLabels {
  color: #484848;
  font-size: 16px;
  margin-bottom: 0;
  text-align: left;
}
.submitButton {
  background-color: #001011 !important;
  text-align: center;
  width: 100%;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 18px !important;
}
.backBtn {
  text-align: center;
  width: 100%;
  height: 34px;
  text-decoration: none;
  border: 2px solid #001011;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 18px !important;
}
.buttonText {
  font-size: 16px;
  color: white;
}
.inputContainer > textarea {
  outline: none;
  background-color: #f6f7fc !important;
  color: #000;
  padding:0.5rem;
  border-radius: 8px;
  border: #484848 1px solid !important;
}

.inputContainer input {
  border: #484848 1px solid !important;
  background-color: #f6f7fc !important;
}

.inputContainer > input,
.react-tel-input > input {
  padding: 5px 0;
  height: 36px;
  border-radius: 8px;
  outline: none;
  background-color: #f6f7fc !important;
  padding-left: 15px;
  color: #000;
}
.react-tel-input {
  height: 46px !important;
  width: 100% !important;
}
.react-tel-input > input {
  width: 100% !important;
  height: 100% !important;
}
.countryContainer {
  border-radius: 5px;
  border: 1px solid #484848;
}
.countryContainer div {
  background-color: #f6f7fc !important;
  color: #000;
  font-size: 12px;
}
.countryContainer > div {
  border-radius: 5px;
  padding: 5px 0;
}
.react-tel-input .country-list {
  background-color: #313150 !important;
  color: white;
}
.react-tel-input .country-list > li:hover {
  color: #313150;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.react-tel-input .flag-dropdown.open,
.selected-flag.open {
  background: rgba(255, 255, 255, 0.6) !important;
}
.countryContainer > div > div {
  border: none !important;
  box-shadow: none !important;
}
#countryFlag {
  border-radius: 5px;
  padding: 0 !important;
}
.react-tel-input > input::placeholder {
  color: rgba(255, 255, 255, 0.8) !important;
  height: 36px !important;
}
.flag-dropdown {
  background-color: #f6f7fc !important;
  border: none !important;
  outline: none;
}
.inputContainer > input::placeholder {
  color: rgba(0, 0, 0, 0.8);
}
.signup {
  margin: 30px 0 0px 0;
}
.signupText {
  color: #1a1a1a;
  margin-bottom: 20px;
}
.signupLink {
  color: #8a7be0 !important;
  font-weight: 500;
  font-size: 14px;
}
.errorMessage {
  color: red;
  padding: 10px 0 20px 0;
}
.remember {
  color: black;
}
.termsMessage {
  font-size: 12px;
  color: #484848;
  padding-left: 5px;
}
.rememberInput {
  background-color: black !important;
  border: white 2px solid !important;
  border-radius: 5px;
}
.checkboxContainer {
  margin: 10px 0;
}
.logoutText {
  font-size: 16px;
}
.logoutButton:hover {
  cursor: pointer;
}
.submitButton:hover {
  cursor: pointer;
}
.forgetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d06283;
}
.lockIcon {
  margin-right: 5px;
}
.termCondition {
  color: #1a1a1a;
  font-size: 12px;
  margin-left: 5px;
}
.termsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.links {
  font-size: 15px !important;
  color: #d06283 !important;
}
.colorGray {
  color: #aebcc9;
}
@media screen and (max-width: 500px) {
  .formLoginContainer {
    width: 250px;
    padding: 20px 20px;
  }
  .formSignContainer {
    width: 250px;
    padding: 20px 20px;
  }
}

#demoButton {
  background-image: linear-gradient(
    to right,
    #d06283 0%,
    #82334b 51%,
    #d06283 100%
  );
  border-radius: 20px;
  border: none;
  cursor: pointer;
  width: 120px;
  height: 40px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  margin: 0 0.75rem;
  animation: backgroundAnimation 1500ms infinite ease-out,
    1500ms infinite ease-in;
}

#demoButton:hover {
  animation: borderPulse 1000ms infinite ease-out, 1000ms infinite ease-in;
}

#loginButton {
  background-image: linear-gradient(
    to right,
    #d06283 0%,
    #82334b 51%,
    #d06283 100%
  );
  border-radius: 20px;
  border: none;
  cursor: pointer;
  width: 120px;
  height: 40px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  margin: 0 0.75rem;
  animation: backgroundAnimation 1500ms infinite ease-out,
    1500ms infinite ease-in;
}

#loginButton:hover {
  animation: borderPulse 1000ms infinite ease-out, 1000ms infinite ease-in;
}

#login {
  background-image: linear-gradient(
    to right,
    #d06283 0%,
    #82334b 51%,
    #d06283 100%
  );
  border-radius: 20px;
  border: none;
  cursor: pointer;
  width: 120px;
  height: 40px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  margin: 0 0.75rem;
}

#login:hover {
  background-position: right center;
  animation: borderPulse 1000ms infinite ease-out, 1000ms infinite ease-in;
}

#contactButton {
  /* background-size: 1px 240px; */
  border-radius: 20px;
  background: transparent;
  border: 1px solid #762e3f;
  cursor: pointer;
  width: 120px;
  height: 40px;
  color: #d06283;
  margin: 0 0.75rem;
}
#contactButton:hover {
  background-image: linear-gradient(
    to right,
    #d06283 0%,
    #82334b 51%,
    #d06283 100%
  );
  color: white;
  border: none;
  transition: 0.5s;
  background-size: 200% auto;
  background-position: right center;
  animation: borderPulse 1000ms infinite ease-out, 1000ms infinite ease-in;
  /* background-position: 100px; */
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, 0.4),
      0px 0px 0px 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255, 0.4),
      0px 0px 0px 10px rgba(255, 255, 255, 0.4);
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: left center;
  }
  50% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}

.arrow {
  position: relative;
  animation: down 800ms infinite;
}

@keyframes down {
  0% {
    top: -3px;
  }
  50% {
    top: 3px;
  }
  100% {
    top: -3px;
  }
}

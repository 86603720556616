.profileCard {
  position: absolute;
  background-color: #F6F7FC;
  width: 45%;
  height: 87%;
  left: 25%;
  min-width:310px;
  top: 15%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profileCard::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profileCard {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.userForm {
  position: absolute;
  background-color: #F6F7FC;
  width: 65%;
  height: 87%;
  left: 17%;
  min-width:310px;
  top: 5%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.userForm::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.userForm {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.logic-sidebar .profile-info {
  display: flex;
  /* position: fixed; */
  margin-bottom: 30px;
  width: 100%;
  color: #989ba1;
  list-style: none;
  font-size: 13px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}

.logic-sidebar .profile-info div {
  flex: 1;
  cursor: pointer;
}

.logic-sidebar .profile-info li.active {
  border-bottom: 2px solid #f37907;
  /* display: inline-block; */
  /* padding-bottom: 10px; */
}

.form_label {
  /* align-items: left; */
  /* text-align: left; */
  color: #484848;
  font-size: 12px;
}

.form_input {
  width: 90%;
  color: #989ba1;
  height: 20px;
  background-color: #313150;
  border-radius: 4px;
}

.editIcon {
  text-align: right;
  color: #989ba1;
}
.MuiFormGroup-root{
  display: flex;
  /* flex-direction: column; */
} 
.app-container {
    /* display: flex;
    height: 100vh;
    width: 100vw;*/
    background-color: pink;
    display: grid;
    grid-template-columns: auto 1fr;
    /* First column takes the space it needs, second column takes the rest */
    height: 100%;
    width: 100%;
    font-weight: '30px';
}
.hr-tab-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    background-color: #f0f0f0;
    /* Adjust as needed */
}

.chatContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #f0f0f0;
}

.messagesContainer {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message {
    background: #e1f5fe;
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0;
}

.inputContainer {
    display: flex;
    width: 100%;
}

.input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    font-family: 'work sans-sarif';
}
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
.navbar-menu {
  margin: 10px;
  padding: 20px;
  text-align: center;
  justify-content: "center";
  height: 100vh;
  background-color: #FFF;
  /* Light pinkish background */
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
}

.burger {
  display: flex;
  justify-content: start;
  /*flex-end;*/
}

.burger img {
  padding: 25px 8px 0px 0px;
  cursor: pointer;
}

.navbar__list {
  list-style-type: none;
  padding: 0;
}

.navbar__li {
  display: inline-block;
  padding: 0px 0px 0px 12px;
  font-size: 16px;
  color: #000000;
  font-family: "Lato";
  font-weight: 400;
  background-color: #e2d6dd;
  height: 55px;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: start;
}

.navbar__li-box:first-child {
  margin-top: 53px;
}

.navbar__li-box {
  height: 55px;
  margin-top: 10px;
}

.navbar__li:hover {
  background-color: #f06cb9;
  /* border-radius: 10px; */
}


.pl {
	display: block;
	width: 3.25em;
	height: 3.25em;
}
.pl__ring,
.pl__ball {
	animation: ring 2s ease-out infinite;
}
.pl__ball {
	animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue), 10%, 10%);
		--fg: hsl(var(--hue), 10%, 90%);
	}
}

/* Animation */
@keyframes ring {
	from {
		strokeDasharray: 0 257 0 0 1 0 0 258;
	}
	25% {
		strokeDasharray: 0 0 0 0 257 0 258 0;
	}
	50%,
	to {
		strokeDasharray: 0 0 0 0 0 515 0 0;
	}
}
@keyframes ball {
	from,
	50% {
		animation-timing-function: ease-in;
		strokeDashoffset: 1;
	}
	64% {
		animation-timing-function: ease-in;
		strokeDashoffset: -109;
	}
	78% {
		animation-timing-function: ease-in;
		strokeDashoffset: -145;
	}
	92% {
		animation-timing-function: ease-in;
		strokeDashoffset: -157;
	}
	57%,
	71%,
	85%,
	99%,
	to {
		animation-timing-function: ease-out;
		strokeDashoffset: -163;
	}
}
.sentiment-score-container {
  max-height: 220px !important;
  /* position: relative;
    width: 97% !important;
    top: 10px; */
  margin: 2rem;
}

.filterContainer {
  background: #fff;
  box-shadow: 0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 12px;
  color: #4e4f52;
  height: 20px;
  margin: 3px;
  font-weight: 500;
  padding: 0.4rem 0.5rem;
}

.settingCard {
  width: 95%;
  height: 100%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.settingCard::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.settingCard {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.settingTab {
  display: flex;
  margin-bottom: 10px;
  color: #989ba1;
  list-style: none;
  font-size: 13px;
  font-weight: 600;
  padding: 20px 10% 10px 10%;
  background-color: #f6f7fc;
  z-index: 2;
}

.logic-sidebar .settingTab div {
  flex: 1;
  cursor: pointer;
}

.logic-sidebar .settingTab li.active {
  border-bottom: 2px solid #f37907;
  /* display: inline-block; */
  /* padding-bottom: 10px; */
}

.userManagementContainer {
  /* background-color: #F6F7FC !important; */
  height: 95vh;
}

.searchAdd {
  padding-top: 20px;
}

.userTable {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

/* .MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */

.MuiDataGrid-root .MuiDataGrid-window {
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* UI for mail-box */

.userManagementContainer_mail {
  text-align: left;
  background-color: #24223a !important;
  height: 95vh;
  /* padding: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.info-box {
  background: #2d2b45;
  width: 523px;
  height: 480px;
  margin-bottom: 120px;
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 40px;
}

.userManagementContainer_mail label {
  display: block;
}

.mailServerInput input {
  background: #313150;
  border: 1px solid #747474;
  width: 335px;
  height: 37px;
  padding: 5px;
  font-size: 13px;
}

.buttonGroup {
  align-self: flex-end;
  margin-right: 80px;
  padding-top: 25px;
}

.info-box button {
  /* width: 51px; */
  /* height: 21px; */
  font-size: 14px;
  color: white;
  border: 0px solid;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
}

.buttonGroup .cancel {
  background: #2d2b45;
  border: 1px solid #6352c6;
}

.buttonGroup .save {
  background: #6352c6;
  border: 1px solid #2d2b45;
}

.accessProperty {
  border-radius: 15px;
  border-width: 10px !important;
  border: solid white !important;
  height: 30px;
}

.accessProperty > p {
  line-height: 0 !important;
  padding: 0 5px;
}

/* User Management PopUp */

.userManagementCard {
  width: 60%;
  height: 550px;
  margin: 5rem auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  background-color: #e5e5e5;
}

.importCard {
  width: 60%;
  height: 400px;
  margin: 5rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e5e5e5;
}

.deleteUserCard {
  width: 40%;
  height: 200px;
  position: relative;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e5e5e5;
}

.userManagementCard .profile-info div {
  flex: 1;
  cursor: pointer;
}

.userManagementSection {
  height: 100%;
  width: 60%;
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.accessContainer {
  background-color: #e5e5e5;
  height: 100%;
}

.margin-0 {
  margin-top: 0 !important;
}

.accessTitle {
  color: white;
}

.profileEditSection {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 30px;
}

.userDetails {
  width: 40%;
  margin: 0 40px;
}

.userSection {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.userSection::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.userSection {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.accessTabs {
  display: flex;
  /* position: fixed; */
  color: #989ba1;
  list-style: none;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 10px;
  background-color: #24223a;
  justify-content: flex-start;
}

.accessTabs li.active {
  border-bottom: 2px solid #f37907;
  /* display: inline-block; */
  /* padding-bottom: 10px; */
}

.accessTabs > div {
  cursor: pointer;
  margin-right: 20px;
}

.tagsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.tagsContainer .filledBtn {
  border-radius: 8px;
  margin: 10px;
  height: 24px;
  padding: 0 5px;
  color: white;
  display: flex;
  align-items: center;
}

.tagsContainer .unfilledBtn {
  border: white 2px solid;
  margin: 10px;
  height: 24px;
  padding: 0 5px;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.closeManagement {
  position: relative;
  top: -1%;
  left: 95%;
  cursor: pointer;
}

.CompanyProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #F6F7FC ; */
  height: 95vh;
}

.CompanyProfile .inputFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* background-color: #fff; */
  width: 35%;
  margin: 30px 0;
  padding: 50px 0;
  border-radius: 4px;
}

.CompanyProfile .inputContainer {
  width: 70%;
  height: 80%;
}

.flag-dropdown {
  border-top: #747474 1px solid !important;
  border-left: #747474 1px solid !important;
  border-bottom: #747474 1px solid !important;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 150px;
  padding: 5px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 14px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* top left*/

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: 46px;
  top: 10px;
  transform: rotate(-45deg);
}

.morale-trend-container {
  margin: 2rem;
  position: relative;
  top: -40px;
}

.filterContainer {
  background: #fff;
  box-shadow: 0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 12px;
  color: #4e4f52;
  height: 20px;
  margin: 3px;
  font-weight: 500;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
}

.action-trend-container div {
  max-height: 250px !important;
  /* width: 97% !important; */
}

.action-trend-container {
  margin: 1rem 2rem 0;
}

.filterContainer {
  background: #fff;
  color: #4e4f52;
  box-shadow: 0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 12px;
  margin: 3px;
  font-weight: 500;
  height: 20px;
  padding: 0.4rem 0.5rem;
}

.compare-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px 5px;
  width: 70px;
  font-size: 14px;
  font-weight: 500 !important;
  background-color: #f6f7fc;
  color: rgba(0, 0, 0, 1);
  outline: none;
  border-radius: 5px;
}

.dndflow {
  flex-direction: column;
  display: flex;
  height: calc(100vh - 100px);
  
}
.logicscreen-sidebar {
  background-color: #f6f7fc;
}
.dndflow aside {
  display: grid;
  height: 80%;

  overflow-y: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 120px;
}

.logic-sidebar .logic-navbar {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  list-style: none;
  font-size: 13px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}

.logic-sidebar .logic-navbar div {
  flex: 1;
  cursor: pointer;
}

.logic-sidebar .logic-navbar li.active {
  color: #5FA7F5;
  border-bottom: 2px solid #5FA7F5;
  display: inline-block;
  padding-bottom: 10px;
}

.logic-sidebar .logic-navbar li.active.filter {
  color: #FFA300;
  border-bottom: 2px solid #FFA300;
}

.logic-sidebar .logic-navbar li.active.model {
  color: #4CDA89;
  border-bottom: 2px solid #4CDA89;
}

.logic-sidebar .logic-navbar li.active.action {
  color: #FF0892;
  border-bottom: 2px solid #FF0892;
}

/* ---------------Filter Section------------- */
.filter-container p {
  text-align: left;
  color: rgba(0, 0, 0, 0, 6) !important;
  padding: 4px 30px;
  font-size: 14px;
  cursor: grab;
}

.dndflow aside .dndnode {
  border-radius: 10px;
  margin: 8px;
  padding: 0 8px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    179.37deg,
    rgb(47, 44, 73) -503.66%,
    #b1d4ec 99.46%
  );
  justify-content: center;
}

.dndflow aside .dnd-model {
  background: linear-gradient(
    179.37deg,
    rgb(47, 44, 73) -503.66%,
    #ADEAC3 99.46%
  ) !important;
}

.dndflow aside .dnd-action {
  background: linear-gradient(
    179.37deg,
    rgb(47, 44, 73) -503.66%,
    #FCD1DE 99.46%
  ) !important;
}

.dndflow aside .dndnode p {
  margin: 0;
  color: "#808288";
}

.dndflow aside > * {
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.react-flow__node {
  z-index: 3;
  transform: translate(471.812px, 183px);
  pointer-events: all;
  opacity: 1;
  height: 119px;
  width: 134px;
  background-size: 40% 40% !important;
}

.react-flow__node.selected {
  box-shadow: 0 0 8px #fff !important;
}
.editMessage {
  color: rgb(255, 255, 255, 0.6);
  font-size: 12px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  bottom: 0;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow .logic-sidebar {
    width: 20%;
    max-width: 300px;
  }
}

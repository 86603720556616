.mailserver {
  font-style: normal;
  color: #fff;
  text-align: start;
  font-size: 1.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 700px;
  position: relative;
  top: 0px;
  z-index: 1;
}


.sentiment-trend-container div {
  max-height: 230px !important;
  /* position: relative;
    width: 97% !important;
    top: 10px; */
}

.sentiment-trend-container {
  margin: 1rem;
}

.filterContainer {
  background: #fff;
  box-shadow: 0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 12px;
  color: #4e4f52;
  height: 20px;
  font-weight: 500;
  margin: 3px;
  padding: 0.4rem 0.5rem;
  overflow: hidden;
}

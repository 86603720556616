.react-chatbot-kit-chat-container {
  width: 100% !important;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0;
}

.bot {
  bottom: 20px;
  right: 40px;
  position: fixed;
  width: 350px;
}

.bot-box {
  width: 350px;
  height: 500px;
  background: #dbd9d9;
  z-index: 7;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  padding: 0;
  position: relative;
}

.bot-close-icon {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 8;
  cursor: pointer;
}

.bot-button {
  cursor: pointer;
  width: 235px;
  height: 70px;
}

.bot-button-text {
  position: absolute;
  background: rgb(46, 78, 237);
  bottom: 22px;
  right: 45px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.navbar {
  position: relative;
  height: 60px;
  display: flex;
  width: 97%;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 4;
}

.navbarLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navbarRight {
  display: flex;
  position: absolute;
  right: 30px;
  justify-content: flex-end;
  align-items: center;
}

.navTab {
  padding: 10px 30px 10px 30px;
  width: 100px;
  color: #1a1a1a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0 4px;
  position: relative;
  top: 10px;
  cursor: pointer;
}

.navTabActive {
  background: #f2f2f2;
  width: 100px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0 4px;
}

.navTabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.navbarLogo {
  margin-right: 30px;
  font-size: 24px;
  /* background-color: #fff; */
}

.navbarRight > svg {
  filter: invert(0.6);
}

.sucessRegisterContainer {
  background-color: #191733;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.successMessage {
  font-size: 36px;
  margin-bottom: 30px;
}

.popUp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 35px 20px;
  text-align: left;
}

.popUpTitle {
  color: #000;
  font-size: 18px;
  cursor: default;
}

.rangeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 2px 7px;
  height: 100%;
}

.rangeBtn:hover {
  cursor: pointer;
}

.submitBtn {
  background-color: #d06283;
  margin: 10px 0 0 10px;
  border-radius: 5px;
  color: #fff;
  height: 34px;
  cursor: pointer;
}

.cancelBtn {
  border: 2px solid #d06283;
  margin: 10px 0 0 10px;
  border-radius: 5px;
  color: #484848;
  height: 32px;
}

.btnOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.fileUploaderContainer {
  color: white !important;
  background-color: #e5e5e5;
  padding: 5%;
  margin: 15%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
}

.mg20 {
  margin: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.container {
  position: relative;
  width: 600px;
  margin: auto;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 300px;
  vertical-align: middle;
  color: black;
}

.list-group {
  padding: 0;
  margin: 0;
}

.list-header {
  margin-top: 10px !important;
}

.upload-message {
  font-weight: bold;
  color: #63a84e;
  margin-top: 20px !important;
}

.upload-message.error {
  color: #da4148;
}

.btn-choose {
  margin-right: 10px !important;
}

.btnAndUpload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uploaderTitle {
  font-size: 48px;
  color: #3f51b5;
}

.workflowLIst {
  background: transparent;
  border: none;
  outline: none;
  width: 40px;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  z-index: 4;
}

#demo-customized-select {
  z-index: 4;
  min-width: 46px;
  min-height: 36px;
  background-color: transparent;
  position: relative;
  left: -20px;
}

.workflowLIst > option {
  color: white;
  background: #24223a;
  outline: none;
  border: none;
  padding: 100px;
  height: 20px !important;
}

.MenuItem {
  color: white !important;
  background-color: rgb(25 23 51) !important;
}

.MenuItem.Mui-selected {
  /* Increase the specificity */
  color: #d06283 !important;
}

.margin35 {
  margin-top: 35px !important;
}
.td-none {
  text-decoration: none !important;
}

.workflowLIst > option {
  color: black;
}

.cancelBtn {
  border: 2px solid #d06283;
  margin: 10px 0 0 10px;
  border-radius: 5px;
}

.btnOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.wrapper {
  margin: 0 auto;
  position: absolute;
  width: 40vw;
}
.wrapper2 {
  margin: 0 auto;
  position: absolute;
  width: 380px;
}

.wrapper .search-input {
  background: #dbd9d9;
  width: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
}

.search-input input {
  height: 40px;
  width: 90%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  font-size: 16px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  background: #dbd9d9;
}

.search-input.active input {
  border-radius: 5px 5px 0 0;
}

.autocom-box {
  padding: 0;
  opacity: 1;
  pointer-events: none;
  max-height: 280px;
  width: 390x;
  position: relative;
  top: 10px;
  overflow-y: auto;
  min-height: 300px;
  z-index: 4;
  background: #dbd9d9;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.autocom-box li {
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}

.search-input.active .autocom-box li {
  display: block;
}
.autocom-box li:hover {
  background: #efefef;
}

.search-input .icon {
  position: absolute;
  right: -10px;
  top: -5px;
  height: 40px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: rgb(54, 41, 183);
  cursor: pointer;
}

.react-datetime-picker__clock {
  display: none;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575px) {
  .navbarLeft {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .navbarLeft {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .navbarLeft {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbarLeft {
    display: flex;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbarLeft {
    display: flex;
  }
}


.notification-panel{
  animation: fadeIn 0.3s ease-in-out;
  z-index: 700;
}


@keyframes fadeIn {
  from {
     opacity: 0;
     transform: translateY(-10px);
  }
  to {
     opacity: 1;
     transform: translateY(0);
  }
}

.input-box {
  position: relative;
  height: 30px;
  /* max-width: 900px; */
  width: 75%;
  background: #fff;
  margin: 0;
  padding: 0.5rem 0;
  border: 1px solid #d06283;
  border-radius: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.ask-result-box {
  position: relative;
  height: 30px;
  /* max-width: 900px; */
  width: 72.5%;
  height: 450px;
  background: #fff;
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #d06283;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.placeholder {
  font-size: 16px;
  color: #888;
  opacity: 0;
  height: 30px;
  text-align: left;
  animation: fadeIn 1s forwards;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.input-box i,
.input-box .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-box i {
  left: 20px;
  font-size: 30px;
  color: #707070;
}
.input-box input {
  height: 100%;
  width: 83%;
  /* max-width: 700px; */
  outline: none;
  font-size: 16px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 25px;
  background-color: transparent;
}
.input-box .button {
  right: 7px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 30px;
  background-color: #d06283;
  cursor: pointer;
}
.input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}

/* Responsive */
@media screen and (max-width: 500px) {
  .input-box {
    height: 66px;
    margin: 0 8px;
  }
  .input-box i {
    left: 12px;
    font-size: 25px;
  }
  .input-box input {
    padding: 0 112px 0 50px;
  }
  .input-box .button {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
  }
}

.emo-progress-label {
    font-size: 12px;
    color: #484848;
    position: relative;
    margin:0 5px;
    /* display:none */
    /* left: 28px */
}

.emo-progress-wrapper div div {
    background-color: #15B9B1 !important;
    position: relative;
    justify-content: center !important;
}
.survey div input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #cf87b4;
  padding: 10px;
  font-size: 14px;
}
.survey {
  min-height: 500px;
  margin: 1rem 2rem;
}
.survey div {
  display: flex;
  justify-content: flex-start;
}

.survey table {
  width: 100%;
  padding: 1em;
  margin: 20px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

.survey table thead {
  background-color: #d06283;
  font-size: 16px;
  color: #fff;
}

.survey table th {
  text-align: left;
  padding: 8px;
  background-color: 1px solid #ffffff;
  color: #cf87b4;
}
.survey table td {
  text-align: left;
  padding: 8px;
}

.survey table tr {
  height: 50px;
}

.survey table tr:nth-child(even) {
  background-color: #e0e0e0;
}
